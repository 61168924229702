<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑' : '添加'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="仓库名称" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
         <!-- 超级管理员选地区 -->
        <el-form-item label="所在地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            filterable
            clearable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
            v-model="form.data.status"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'storeListEdit',
    data() {
      return {
        roleType: getStorage('role_type'),
        login_province: getStorage('province'),
        login_city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            area:[],
            province: '',
            city: '',
            status: true,
          },
          rules: {
            name: [{ required: true, message:'请输入姓名', trigger: 'change'}],
            area: [{ required: true, message:'请选择所在地区', trigger: 'change'}],
          }
        },
        areaArr: [],
      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.area = [row.province, row.city]
            this.form.data.status = row.status == 1 ? true : false;
          })
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              province: this.roleType == 1 ? this.form.data.area[0] : this.login_province,
              city: this.roleType == 1 ? this.form.data.area[1] : this.login_city,
              status: this.form.data.status == true ? 1 : 2
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/stash/edit' : '/admin/stash/add'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
