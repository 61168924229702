<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <!-- 只有超级管理员有创建的按钮，其他角色没有创建的按钮 -->
      <div class="btnAdd" v-if="is_sys==1">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:status="{row}">
        <span :style="{color: row.status == 1 ? '' : '#f56c6c'}">{{row.status == 1 ? '启用' : '禁用'}}</span>
      </template>
      <template v-slot:area="{row}">
        <span>{{row.province_str}}{{row.city_str}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="handleStatus(row)" class="">{{row.status == 1 ? '禁用' : '启用'}}</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'

export default {
  name: '',
  components: {
    Edit,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "仓库名称", hidden: false },
        { name: "area", label: "所在地区", hidden: false},
        { name: "spec_count", label: "存储商品项数", hidden: false},
        { name: "inventory", label: "当前库存数", hidden: false},
        { name: "status", label: "状态", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/stash/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 禁用、启用
    handleStatus(row) {
      let str = row.status == 1 ? '禁用' : '启用'
      this.$confirm(`请确认${str}操作吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.post('/admin/stash/operation',{id:row.id}).then(res => {
          if(res.code === 1) {
            this.$message.success("操作成功！")
            this.getTable();
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
